
import { defineComponent, reactive } from "vue";

interface IState {
  showTimmaBooking: boolean;
  showClientoBooking: boolean;
}

export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    const state = reactive<IState>({
      showTimmaBooking: false,
      showClientoBooking: false,
    });

    const timmaBook = () => {
      state.showTimmaBooking = true;
      state.showClientoBooking = false;
    };

    const clientoBook = () => {
      state.showTimmaBooking = false;
      state.showClientoBooking = true;
    };

    return {
      state,
      timmaBook,
      clientoBook,
    };
  },
});
